@import '~bootstrap/dist/css/bootstrap.css';
@import './assets/css/material/material-theme.scss';
@import './assets/css/material/ngselect.scss';
@import './assets/css/material/theme-variables.scss';
@import "./assets/css/material/fonts/SFProDisplay.css";
@import '~ngx-toastr/toastr';
@import '~sweetalert2/dist/sweetalert2.min.css';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-calendar-controls{
  direction: ltr;
}
.text-yellow{
  color: #E8AB21;
}
.tab-content{
  min-height: 50vh !important;
}
.countdown {
  .value {
    color: $highlight-text;
    font-weight: bold;
  }
}
.green-mark{
  color: green;
  font-size: 25px;
}
.seeMore{
  margin: 1rem 0;
}
.seeMore a{
  font-size: 20;
}
.countdown-container {
  background-color: $highlight-background;
}
countdown{
  color: $highlight-text;
  font-size: 20px;
}
.text-primary{
  color:$highlight-text !important;
}
.mat-error{
  height: 12px;
}
/* You can add global styles to this file, and also import other style files */

.tabel-row{
  h4{
    margin-bottom: 0;
  }
  label{
    margin-bottom: 0;
  }
}
.side-bar-main{
  width: 25%;
}
#mainContainerProfile{
  width: 75%; 
  padding-right: 20px;
}
.action-btn{
  cursor: pointer;
}
@media (max-width: 767px) {
  .side-bar-main{
    width: 100% !important;
  } 
  .sm-f-0{
    flex: 0 !important;
  }
}
mat-header-cell{
  text-align: center;
}
mat-cell{
  text-align: center;
}
.no-wrap{
  word-break: break-word;
  white-space: pre-wrap;
  -moz-white-space: pre-wrap;
}
.pointer-cursor{
  cursor: pointer;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  // color:$primary-color !important;
  color: #7c5702 !important;
  background-color: white !important;
}

.nav-pills .nav-link, .nav-pills .show .nav-link {
  color: #e8ab21 !important;
  opacity: .8;
}

.nav-pills .nav-link:hover, .nav-pills .show .nav-link:hover {
  color: rgb(206, 205, 205) !important;;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
// ngb-datepicker-navigation-select>.custom-select{
//   padding: 0 !important;
// }
ngb-datepicker-month >div >div .bg-primary{
  background-color:$primary-color !important;
}
.submitButton{
  width: auto;
  height: 45px;
}

#marketPlace > pagination-template > ul > li.current{
  background: #E8AB21;
}
.mat-form-field-label{
  min-height: 20px !important;
}
.border-primary {
  border-color:#E8AB21  !important;
  border: solid;
  border-radius: 5px;
}