/* @font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Black.woff2') format('woff2'),
        url('SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
} */

/* @font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Light.woff2') format('woff2'),
        url('SFProDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
  font-family: 'SFProDisplay';
  src: url('SFProDisplay-Thin.woff2') format('woff2'),
  url('SFProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('SFProDisplay-Semibold.woff2') format('woff2'),
  url('SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
