$primary-color: #e8ab21;
$primary-light: #ebf5f3;
$secondary-color: #f5f5f5;
$highlight-color: #e3c255;
$highlight-background: rgba(227, 131, 85, 0.21);
$shadow: rgba(0, 0, 0, 0.06);

$primary-text: #e8ab21;
$secondary-text: #7e7e7e;
$highlight-text: #ffc401;
$light-border: rgba(194, 194, 194, 0.2);
$text-danger: #881b16;

$white: #FFFFFF;
$dark-grey: #dfdfdf;
$danger: #d33f38;

/* ============================== */
/* Aspect Ratio */
/* ============================== */

[style*='--aspect-ratio'] > :first-child {
  width: 100%;
}

[style*='--aspect-ratio'] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*='--aspect-ratio'] {
    position: relative;
  }

  [style*='--aspect-ratio']::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*='--aspect-ratio'] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
